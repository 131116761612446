import type { SeoMetatags } from '@/types/storyblok'

export const isSeoMetatags = (meta: unknown): meta is SeoMetatags => {
  return (meta as SeoMetatags)?.plugin === 'seo_metatags'
}

/**
 * Set SEO meta tags to the head based on the current Story
 */
export default function useSeo() {
  const siteConfig = useSiteConfig()
  const config = useConfigStore()

  const { story, communityName, communityContent, communityRelation } =
    storeToRefs(useCurrentStoryStore())

  const meta: Ref<SeoMetatags | undefined> = computed(() =>
    isSeoMetatags(story.value?.content?.meta)
      ? story.value.content?.meta
      : undefined,
  )

  const computedTitle = computed(() => {
    if (meta.value?.title) return meta.value.title
    if (communityRelation.value === 'parent' && story.value?.name)
      return story.value.name
    if (communityRelation.value === 'self' && communityName.value)
      return communityName.value
    return story.value?.name ?? siteConfig.name
  })

  const computedDescription = computed(() => {
    if (meta.value?.description) return meta.value.description
    if (story.value?.content?.shortDescription)
      return story.value.content.shortDescription.toString()
    if (communityRelation.value === 'parent') return communityName.value
    if (
      communityRelation.value === 'self' &&
      communityContent.value?.shortDescription
    )
      return communityContent.value.shortDescription.toString()
    return siteConfig.description
  })

  const computedImage = computed(() => {
    // Check for OG image in meta
    const ogImageMeta = meta.value?.og_image
    if (ogImageMeta) return ogImageMeta

    // Check for gallery image in hero
    const hero = story.value?.content?.hero?.[0]
    const galleryItem = hero?.gallery?.[0]?.galleryItems?.[0]?.filename
    if (galleryItem) return galleryItem

    // Check fall back to site config OG image
    const siteOgImage = config.siteConfigBlok?.meta?.og_image
    return siteOgImage || undefined
  })

  const computedBgColor = computed(() => {
    if (communityRelation.value)
      return `[${communityContent.value?.backgroundDark.color}]`
    return undefined
  })

  const computedTextColor = computed(() => {
    if (communityRelation.value)
      return `[${communityContent.value?.contentDark.color}]`
    return undefined
  })

  const computedBrandImage = computed(() => {
    if (communityRelation.value)
      return communityContent.value?.logoDark?.filename
    return undefined
  })

  // Set SEO meta tags - reactive
  useSeoMeta({
    title: computedTitle,
    description: computedDescription,
  })

  // Set OG image - non-reactive
  // Note: these tags will NOT change on client side page navigation, this is expected!
  // The OG tags are only needed for sharing and SEO, so they don't need to be reactive
  // as any external service accessing the page will only read the tags for a given URL on page load
  defineOgImageComponent('OgImageDefault', {
    title: computedTitle.value,
    description: computedDescription.value,
    image: computedImage.value,
    bgColor: computedBgColor.value,
    textColor: computedTextColor.value,
    brandImage: computedBrandImage.value,
  })
}
