<template>
  <StoryblokComponent v-if="story" :blok="story.content" />
</template>

<script setup lang="ts">
const { reset: resetStory, setStory } = useCurrentStoryStore()

const slug = useSlug().slugString
const story = await useStoryblokHelpers().fetchAsyncStory(slug)
await setStory(story)

if (!story.value) {
  resetStory()
  throw createError({
    fatal: true,
    statusCode: 404,
    statusMessage: 'Page not found',
  })
}

useSeo()
</script>
